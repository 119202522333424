<template>
  <div class="section_wrap pt-50 type-bbs" style="padding-bottom: 70px">
    <div style="flex: 1">
      <div class="under-line pb-10 flex-row title">
        <h3 class="mr-10">{{ program.title }}</h3>
        <h6>자주 묻는 질문을 확인하세요.</h6>
      </div>
      <ul class="mt-30">
        <li v-for="item in items_category" :key="item.faq_number" class="under-line mb-20">
          <a class="text-gray" @click="clickCategory(item)" :class="{'text-black' : item_selected_category.faq_number === item.faq_number}">{{ item.faq_title }}</a>
        </li>
      </ul>
    </div>

    <div style="flex: 3" class="ml-30">
      <div class="under-line pb-10 flex-row title">
        <h3 class="mr-10">전체</h3>
      </div>
      <div class="mt-30" v-if="!item_selected_faq">
        <ul>
          <li v-for="(item) in items_faq" :key="item.faq_number" class="under-line mb-20" style="display: flex;" @click="getFaqInfo(item)">
            <div class="faq_sub_list_arrow mr-10 text-center" style="width: 20px; background-color: #e5e5e5; border-radius: 20px">Q</div>
            <a class="text-black">{{ item.faq_title }}</a>
          </li>
        </ul>
      </div>

      <div v-else>
        <div class="under-line mb-20 mt-25" style="display: flex; padding-bottom: 20px">
          <div class="faq_sub_list_arrow mr-10 text-center detail_title">Q</div>
          <h3 class="text-black">{{ item_selected_faq.faq_title }}</h3>
        </div>

        <div class="under-line mb-20 mt-25" style="display: flex; padding-bottom: 20px">
          <div class="text-black" v-html="item_selected_faq.faq_contents"></div>
        </div>

        <div class="mt-25" style="display: flex; justify-content: end;">
          <button class="btn-inline radius-30 pa-10-20" @click="backFaqList">목록</button>
        </div>
      </div>

      <div class="pagination">
        <button @click="page_unit--" :disabled="canDecrement">
          <v-icon
              style="color: var(--gray02); width: 10px"
          >mdi-chevron-double-left
          </v-icon>
        </button>
        <button v-for="page in pages" :key="page" :class="{'active' : currentPage === page }" @click="currentPage = page">{{ page }}</button>
        <button @click="page_unit++" :disabled="canIncrement">
          <v-icon
              style="color: var(--gray02); width: 10px"
          >mdi-chevron-double-right
          </v-icon>
        </button>
      </div>

    </div>


  </div>
</template>

<script>
export default {
  name: "bbs"
  , props: ['user']
  , data: function () {
    return {
      program: {
        name: '커뮤니티'
        , title: 'FAQ'
        , b_code: this.$route.params.b_code
        , type: 'mypage'
      }
      , items: []

      , items_category: []
      , item_selected_category: {
        faq_number: '',
      }

      , items_faq: []
      , item_selected_faq: undefined

      , currentPage: 1
      , page_unit : 1
      , page_tot_count : 1
    }
  }

  , computed: {
    pages() {

      let endPage = this.page_unit * 5
      let startPage = endPage - 4

      if (this.page_tot_count < endPage) {
        endPage = (endPage - (endPage - this.page_tot_count))
      }

      let pages = []
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i)
      }
      return pages
    }
    , canDecrement() {
      if (this.page_unit <= 1) {
        return true
      }
      return false
    }

    , canIncrement() {
      if (this.page_tot_count < (this.page_unit * 5)) {
        return true
      }
      return false
    }

  }


  ,  watch: {
    currentPage() {
      this.$nextTick(() => {
        // 페이지 변경시에는 데이터를 업데이트 합니다.
        // 이곳에서 API를 호출하여 새로운 데이터를 가져올 수 있습니다.
        console.log("Page changed: ", this.currentPage);
        this.getFaqList('', this.currentPage)
      });
    },
  }

  , methods: {

    initCategory: async function () {
      try {
        this.$bus.$emit('on', true)

        const result = await this.$request.init({
          method: 'post'
          , url: this.$api_url.api_path.get_faq_category
          , data: {
            member_number: this.user.member_number
          }
          , type: true
        })
        if (result.success) {
          this.items_category = result.data.faq_catgy_list
          if (this.items_category.length > 0) {
            this.item_selected_category = this.items_category[0]
            await this.getFaqList(this.item_selected_category.faq_number)
          }
        } else {
          throw result.message
        }

      } catch (e) {
        console.log(e)
        this.$bus.$emit('notify', {type: 'error', message: e})
      } finally {
        this.$bus.$emit('on', false)
      }

    }

    , clickCategory(item_selected_category) {
      this.item_selected_category = item_selected_category
      this.getFaqList(this.item_selected_category.faq_number)
    }

    , getFaqList: async function(faq_number = '', page_number = 1){

      if (this.item_selected_category.faq_number) {
        faq_number = this.item_selected_category.faq_number
      }

      try {
        const result = await this.$request.init({
          method: 'post'
          , url: this.$api_url.api_path.get_faq_list
          , data: {
            member_number: this.user.member_number
            , faq_number: faq_number
            , page_number: page_number
            , pagerecnum: this.$language.base.pagerecnum
          }
          , type: true
        })
        if (result.success) {
          this.items_faq = result.data.faq_list
          this.item_selected_faq = undefined
          this.page_tot_count = result.data.page_info.page_tot_count
        } else {
          throw result.message
        }
      }catch (e) {
        console.log(e)
        this.$bus.$emit('notify',  { type: 'error', message: e})
      }finally {
        this.$bus.$emit('on', false)
      }

    }

    ,getFaqInfo: async function(item){
      try {
        const result = await this.$request.init({
          method: 'post'
          , url: this.$api_url.api_path.get_faq_info
          , data: {
            member_number: this.user.member_number
            , faq_number: item.faq_number
          }
          , type: true
        })
        if (result.success) {
          this.item_selected_faq = item
          this.item_selected_faq['faq_contents'] = result.data.faq_contents
        } else {
          throw result.message
        }
      }catch (e) {
        console.log(e)
        this.$bus.$emit('notify',  { type: 'error', message: e})
      }finally {
        this.$bus.$emit('on', false)
      }
    }

    ,backFaqList() {
      this.item_selected_faq = undefined
      this.getFaqList()
    }





  }

  ,created() {
    this.$emit('onLoad', this.program)
    this.initCategory()
  }
}
</script>

<style scoped>
.section_wrap {
  display: flex;
  flex-wrap: wrap;
}

.type-bbs h6 {
  line-height: 260%;
}

.type-bbs li.under-line {
  padding-bottom: 10px;
}

.type-bbs .title {
  border-bottom: 1px solid #b2b2b2; box-sizing: content-box
}

.type-bbs .text-gray {
  color: var(--light-Gray03);
}

.type-bbs .text-black {
  color: var(--black);
}

.type-bbs .detail_title {
  width: 35px; height: 35px; line-height: 200%; background-color: #e5e5e5; border-radius: 20px
}

.type-bbs .btn-inline {
  background-color: white;
  color: var(--blue02);
  border: 2px solid var(--blue02); /* Green */
}

.type-bbs .btn-inline:hover {
  background-color: var(--blue02) !important;
  color: white;
}


.type-bbs .pagination {
  display: inline-block;
}

.type-bbs .pagination button {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 0 4px;
}

.type-bbs .pagination button.active {
  background-color: var(--blue02);
  color: white;
  border: 2px solid var(--blue02);
}

.type-bbs .pagination button:hover:not(.active) {background-color: #ddd;}

</style>